// Google Analytics Tracking
var gat = function(category, action, tag, value, nonInteraction){
	if(typeof ga == 'function') {
    	var params = {
			'hitType': 'event',          // Required.
			'eventCategory': category,   // Required.
			'eventAction': action,      // Required.
			'eventLabel': tag,
			'eventValue': value
		};
		if(typeof nonInteraction != 'undefined' && nonInteraction) params.nonInteraction = 1;
    	// console.info('GAT', params);
    	if(typeof CONFIG.get('ga_code') !== 'undefined' && CONFIG.get('ga_code').length) {
		ga('send', params);
		}
	}
};
// Google Tags Manager Tracking
var gtmt = function(event, data){
	if(typeof gtm_dataLayer == 'object') {
		if(typeof data == 'undefined') data = {};

    	var params = $.extend({
			'event': event
		}, data);
    	//console.info('GTMT', params);
    	gtm_dataLayer.push(params);
	}
};

if(typeof CONFIG.get('ga_code') !== 'undefined' && CONFIG.get('ga_code').length) {
	(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
		(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
		m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
	})(window,document,'script','//www.google-analytics.com/analytics.js','ga');
	ga('create', CONFIG.get('ga_code'), CONFIG.get('SERVER_DOMAIN'));
	ga('send', 'pageview');
}
if(typeof CONFIG.get('gtag_code') !== 'undefined' && CONFIG.get('gtag_code').length) {
	// Global site tag (gtag.js) - Google Analytics 
	window.dataLayer = window.dataLayer || [];
	function gtag(){dataLayer.push(arguments);}
	gtag('js', new Date());

	gtag('config', CONFIG.get('gtag_code'), {
	  send_page_view: false
	});

}
